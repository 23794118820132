import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import LoanCalculator from '../components/Forms/LoanCalculator'
import { Container as BlockContainer, Features } from '../components/Blocks'
import { Hero, BecomeMember } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'
import LoansForm from '../components/Sections/LoansForm'

const IndexPage = () => (
  <Layout>
    <SEO title="Loans" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src='app/Koopinoy-Loans.jpg' height={600} x={100} />
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">What we Offer?</h1>
              <div className="text-justify">
                <p>Are you looking to take a loan may it be for housing, vehicle or for your business? or just looking for a quick cash for  emergency needs. KooPinoy will have you covered. We have a wide range of bespoke for loans. New or not, we hope to meet our members needs with optimal services and get the job their need doing done, quickly, easily, safely and privately. In KooPinoy, when you borrow, you'll get a portion of what you've paid. Want to know how? Feel free to find us on Facebook and browse some of our many several hundred reviews, or contact us to discuss our loan plans.</p>
              </div>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">KooPinoy Loan Name</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle fa-3x" title="MULTIPURPOSE LOAN" isHtml>
                <p>**Interest rate of 24% per annum</p>
                <p>**Maximum loanable of Php30,000.00</p>
                <p>**Ten (10) months amortization</p>
                <p>**Service fee 2%</p>
                <p>**For regular members only</p>
              </Features>
          </Col>
          {/*<Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle-o fa-3x" title="MEMBER'S EASY EMERGENCY LOAN (MEEL)" isHtml>
                <p>**Interest rate: 12%; Penalties: 12%</p>
                <p>**maximum loanable of Php30,000.00</p>
                <p>**for regular employee of both public and private companies.</p>
                <p>**maximum term of 12 months</p>
                <p>**for regular members only</p>
                <p>**One (1) co-maker; SIDC Associate or Regular Member</p>
              </Features>
          </Col>*/}

          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle fa-3x" title="MEMORIAL LOT LOAN" isHtml>
                <p>**Minimum term: One (1) year</p>
                <p>**Maximum term: Five (5) years</p>
                <p>**Interest rate: 12% per annum; Penalties: 12% per annum</p>
                <p>**Loanable amount depending on the price of lot</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle-o fa-3x" title="STL-CASH LOAN" isHtml>
                <p>**Interest rate: 14%; penalties: 12% per annum</p>
                <p>**Eight (8) and Sixteen (16) months lump sum</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle-o fa-3x" title="MTL-CASH LOAN" isHtml>
                <p>**Interest rate: 14%; penalties: 18% per annum</p>
                <p>**Every six (6) months installment</p>
                <p>**Term two (2) years</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle-o fa-3x" title="LTL-CASH LOAN" isHtml>
                <p>**Interest rate: 15%; penalties: 18% per annum</p>
                <p>**Monthly amortizations</p>
                <p>**Maximum Loanable: Php5,000,000.00</p>
                <p>**Up to Five (5) years</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle fa-3x" title="VEHICLE LOAN - CAR" isHtml>
                <p>**Interest: 13% per annum</p>
                <p>**Penalty: 24% per annum</p>
                <p>**Terms up to Five (5) years</p>
                <p>**Brand New or second hand unit</p>
                <p>**Maximum Loanable: Php5,000,000.00</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle-o fa-3x" title="BUSINESS LOAN" isHtml>
                <p>**Seven (7) years term</p>
                <p>**Interest rate: 11% per annum</p>
                <p>**Maximum Loanable: Php20,000,000.00</p>
                <p>**Service Charge:1%</p>
                <p>**Penalties: 24% per annum</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle fa-3x" title="HOUSING LOAN" isHtml>
                <p>**For new construction or renovation of house</p>
                <p>**Interest rate: 10% per annum</p>
                <p>**Penalty: 24% per annum</p>
                <p>**Terms up to Twenty (20) years</p>
                <p>**Maximum Loanable amount: Php10,000,000.00</p>
                <p>**House renovation: One (2) Million</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle-o fa-3x" title="MOTORCYCLE / TRICYCLE LOAN" isHtml>
                <p>**For Regular and Associate member</p>
                <p>**Terms up to 3 years term</p>
                <p>**Interest rate: 14% per annum</p>
                <p>**Penalty: 24% per annum</p>
                <p>**Maximum Loanable: Motorcycle: Php120,000.00 Tricycle: Php120,000.00</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle fa-3x" title="APPLIANCE LOAN" isHtml>
                <p>**At Least one (1) year member of the cooperative</p>
                <p>**Regular and Associate members</p>
                <p>**For brand new appliance only</p>
                <p>**Maximum Loanable: Php30,000.00</p>
                <p>**Interest rate: 14% per annum</p>
                <p>**Penalty: 14% per annum</p>
                <p>**Term: Warranty of the Appliance maximum of 1.5 years</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle-o fa-3x" title="MICRO & SMALL ENTERPRISE LOAN" isHtml>
                <p>**Minimum Loanable: Php10,000.00, Term: 6 months</p>
                <p>**Maximum Loanable: Php300,000.00, Term: 36 months</p>
                <p>**Interest: 24% per annum; Service Fee of 2.5%</p>
                <p>**Notarial and Filing Fee; Penalties: 2% per month</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle fa-3x" title="AGRICULTURAL PRODUCTION LOAN" isHtml>
                <p>**Open to small farmers or fisherfolks who are regular or associate members in good standing engaged in livestock/crop production.</p>
                <p>**Livestock inputs: feeds only</p>
                <p>**Crop production: organic and inorganic fertilizers, pesticides, herbicides, seeds.</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle-o fa-3x" title="SIDC EXPANDED CREDIT LINE (ECL - FEEDS)" isHtml>
                <p><h5><b>Hog Fattening</b></h5></p>
                <p>**Loan in the form of feeds</p>
                <p>**Term: Five (5) months</p>
                <p>**Credit of P6,000.00 per piglet/fattener</p>
                <p>**Maximum: P5,000,000.00</p>
                <p>**Interest rate: 15% per annum; Penalties: 24% per annum</p>
                <p><h5><b>Hog Breeding</b></h5></p>
                <p>**Loan in the form of feeds</p>
                <p>**Term: Seven (7) months</p>
                <p>**Credit of P10,000.00 per sow/breeder</p>
                <p>**Maximum: P5,000,000.00</p>
                <p>**Interest rate: 15% per annum; Penalties: 24% per annum</p>
                <p><h5><b>Broiler</b></h5></p>
                <p>**Loan in the form of feeds</p>
                <p>**Term: Two (2) months</p>
                <p>**Credit of P10,000.00 per 100 heads of broiler</p>
                <p>**Interest rate: 15% per annum; Penalties: 24% per annum</p>
                <p><h5><b>Duck Layer</b></h5></p>
                <p>**Loan in the form of feeds</p>
                <p>**Term: Two (2) months</p>
                <p>**Credit of P16,000.00 per 100 heads of duck layer</p>
                <p>**Interest rate: 15% per annum; Penalties: 24% per annum</p>
              </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle-o fa-3x" title="AGRIFARM EQUIPMENT LOAN" isHtml>
                <p>**One (1) year up to Three (3) years</p>
                <p>**Maximum Loanable: Php1,500,000.00</p>
                <p>**Interest rate: 14% per annum</p>
                <p>**Penalties: 24% per annum</p>
              </Features>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer paddingTop={5} paddingBottom={0} dark id="features">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
              <h2 className="pb-4 text-dark">Loyalty Reward Program</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col lg="4" md="6" className="text-justify">
              <h4 className="text-primary">Gold Category</h4>
              <p>*2% interest discount applies to all loan products except: Agricultural Production Loan and Emergency Loan</p>
              <p>*1% discount on service charge</p>
              <p>*Loanable amount up to 80% of the fair market value of the real estate property collateral - with Tax Declaration Only (60%) or Titled Property (80%)</p>
              <p>*Loanable amount up to 90% of savings and time deposit collateral with no Negative CIBI findings</p>
              <p>*No Barangay Clearance required</p>
              <h4 className="text-primary">Bronze Category</h4>
              <p>*Regular rates and charges apply.</p>

          </Col>
          <Col md="4" className="my-3 d-none d-lg-block">
              <img className="img-fluid d-block" alt="KOOPINOY FEATURES" src={getAssetUrl('app/Koopinoy_Logo_Square.jpg')} />
          </Col>
          <Col lg="4" md="6" className="text-justify">
              <h4 className="text-primary">Silver Category</h4>
              <p>*1% interest discount applies to all loan products except: Agricultural Production Loan and Emergency Loan</p>
              <p>*No co-maker needed for collateralized loan</p>
              <p>*Emergency loan up to Php80,000.00 (1 day processing and no co-maker required)</p>
              <p>*Loanable amount up to 80% of savings and time deposit collateral</p>
              <p>*No barangay clearance required</p>
              <h4 className="text-primary">STUDY NOW, PAY LATER</h4>
              <p>*Open to Regular Members or his/her children and siblings who wants to enter to College or Post Graduate Courses.</p>
              <p>*With real estate collateral required.</p>
              <p>*Tuition fee and monthly allowances can be loan in this program.</p>
              <p>*The payment term will start one year after graduation.</p>
          </Col>
          </Row>
      </Container>
    </BlockContainer>
    {/* <BlockContainer padding={3}>
        <Container>
          <Row>
            <Col md="12">
              <Table className="text-center">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-center"><h3>Loan amount</h3></th>
                    <th className="text-center"><h3>Interest</h3></th>
                    <th className="text-center"><h3>Monthly rate</h3></th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="text-center">Sample amount to loan</th>
                    <th className="text-center">Sample interest</th>
                    <th className="text-center">Payback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
    </BlockContainer> */}
    <LoansForm />
    <LoanCalculator />
    <BecomeMember />
  </Layout>
)

export default IndexPage
