import React from 'react'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col } from 'reactstrap'
import { getAssetUrl } from '../../services/cdn'


class LoansForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            modalTitle: '',
            modalDescription: '',
            modalBottomDescription: '',
            modalSrc: '',
            modalLink: '',
            modalAlt: ''
        }
    }
    toggle = () => {
        this.setState((state, props) => {
            return {
                isModalOpen: !state.isModalOpen
            }
        })
    }
    open = (data) => {
        this.setState({
            isModalOpen: true,
            modalTitle: data.title,
            modalDescription: data.description,
            modalBottomDescription: data.bottomDescription,
            modalSrc: data.src,
            modalAlt: data.alt,
            modalLink: data.link
        })
    }
    render() {
        return (
            <>
                <BlockContainer padding={0} className="pb-5">
                    <Container>
                        <Row>
                            <Col md="12" >
                            <h1 className="mb-3">Loans Form</h1>
                            </Col>
                            <Col md="12" >
                                <h3 className="mb-3">Loan Application Form</h3>
                                {/*Thumbnail
                                <p align="center">
                                  <img className="img-fluid d-block pt-5" alt="Loan Application Form" src={getAssetUrl('app/tmb_customerloanapplicationform.png')} class="center" />
                                </p>*/}
                                <p>
                                  <a target="_blank" rel="noreferrer" href={getAssetUrl('../documents/CustomerLoanApplicationForm.pdf')}>
                                  <button class="btn btn-primary" >Download Form</button>
                                  </a>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </BlockContainer>
                </>
        )
    }
}

export default LoansForm
